import React from 'react'

const content = {
    title: {
        en: "Kiteactive",
        gr: "Kiteactive", // Same as English
        du: "Kiteactive",
    },
    subtitle: {
        en: "What it’s all about",
        gr: "Worum es geht", // German translation
        du: "Wat het allemaal om gaat",
    },

    para: {
        en: "In 2011 we expanded our destinations to include Tarifa, Brazil and in 2012 Zanzibar. This was followed by many other unique destinations. Each location has its own advantages from low-budget to ultimate experience. But what is always the same with every holiday is the unique location, good kite spot, relaxed atmosphere, active no-wind options, and our experienced instructors.",
        gr: "Im Jahr 2011 erweiterten wir unsere Reiseziele um Tarifa, Brasilien und im Jahr 2012 um Sansibar. Es folgten viele weitere einzigartige Destinationen. Jede Location hat ihre eigenen Vorteile, von kostengünstig bis zur ultimativen Erfahrung. Aber was bei jedem Urlaub gleich bleibt, ist der einzigartige Standort, der gute Kite-Spot, die entspannte Atmosphäre, die aktiven Möglichkeiten ohne Wind und unsere erfahrenen Trainer.", // German translation
        du: "In 2011 hebben we onze bestemmingen uitgebreid om Tarifa, Brazilië en in 2012 Zanzibar op te nemen. Dit werd gevolgd door veel andere unieke bestemmingen. Elke locatie heeft zijn eigen voordelen, van laagbudget tot ultieme ervaring. Maar wat altijd hetzelfde is met elke vakantie is de unieke locatie, goede kite spot, ontspannen sfeer, actieve no-wind opties en onze ervaren instructeurs.",
    },
}

const textdata = [
    {
        id: "1",
        title: {
            en: "The Heartbeat of KiteActive",
            gr: "Der Herzschlag von KiteActive", // German translation
            du: "De Hartslag van KiteActive"
        },
        para: {
            en: "KiteActive’s mission is to give you the best kite/holiday. We offer kitesurfing lessons and clinics in the most beautiful places in the world. Thanks to our professional team of instructors, many locations have already been discovered and KiteActive has made a selection of the best destinations for you. By making competitive deals with accommodations at an early stage, you can always stay cheaply through us at the most beautiful locations directly or close to the beach.",
            gr: "Die Mission von KiteActive ist es, Ihnen den besten Kite-Urlaub zu bieten. Wir bieten Kitesurfkurse und Kliniken an den schönsten Orten der Welt an. Dank unseres professionellen Teams von Ausbildern wurden bereits viele Orte entdeckt, und KiteActive hat für Sie die besten Reiseziele ausgewählt. Durch frühe, wettbewerbsfähige Absprachen mit Unterkünften können Sie immer günstig bei uns an den schönsten Orten, direkt oder in Strandnähe, wohnen.", // German translation
            du: "De missie van KiteActive is om je de beste kite/vakantie te geven. We bieden kitesurflessen en clinics aan op de mooiste plekken ter wereld. Dankzij ons professionele team van instructeurs zijn veel locaties al ontdekt en heeft KiteActive een selectie gemaakt van de beste bestemmingen voor jou. Door concurrerende deals met accommodaties in een vroeg stadium te maken, kun je altijd goedkoop bij ons verblijven op de mooiste locaties, direct of dichtbij het strand."
        }
    },
    {
        id: "2",
        title: {
            en: "The KiteActive Path to Wellness",
            gr: "Der KiteActive Weg zum Wohlbefinden", // German translation
            du: "Het KiteActive Pad naar Welzijn"
        },
        para: {
            en: "From 2015 onwards we started to focus on every kitesurfer, from beginner to advanced, from the adventurous kiter who wants to travel alone to someone who wants to join a fun group. KiteActive – By kitesurfers, for kitesurfers. both on the surfboard and on the yoga mat and be more aware of your mind and body connection. Immersed in nature, the ocean and good vibes, we have the perfect setup for a feelgood week that definitely will give you a positive boost.",
            gr: "Ab 2015 begannen wir, uns auf jeden Kitesurfer zu konzentrieren, vom Anfänger bis zum Fortgeschrittenen, vom abenteuerlustigen Kiter, der allein reisen möchte, bis hin zu jemandem, der sich einer lustigen Gruppe anschließen möchte. KiteActive – Von Kitesurfern, für Kitesurfer. Sowohl auf dem Surfbrett als auch auf der Yogamatte und um das Bewusstsein für die Verbindung von Geist und Körper zu stärken. Eingetaucht in die Natur, den Ozean und gute Schwingungen, haben wir das perfekte Setup für eine Feelgood-Woche, die Ihnen definitiv einen positiven Schub geben wird.", // German translation
            du: "Vanaf 2015 zijn we begonnen met focussen op elke kitesurfer, van beginner tot gevorderd, van de avontuurlijke kiter die alleen wil reizen tot iemand die zich bij een leuke groep wil voegen. KiteActive – Door kitesurfers, voor kitesurfers. Zowel op het surfboard als op de yogamat en bewuster worden van de verbinding tussen geest en lichaam. Ondergedompeld in de natuur, de oceaan en goede vibes, hebben we de perfecte opstelling voor een feelgood week die je zeker een positieve boost zal geven."
        }
    },
    {
        id: "3",
        title: {
            en: "Living the KiteActive Lifestyle",
            gr: "Das KiteActive-Lifestyle leben", // German translation
            du: "Leven volgens de KiteActive levensstijl"
        },
        para: {
            en: "Embrace the freedom and excitement that comes with the KiteActive lifestyle! Whether you're soaring through the skies on a kite or enjoying the sun on pristine beaches, every moment is an opportunity for adventure and connection with nature. Our community thrives on passion, camaraderie, and the thrill of exploration, bringing together kitesurfing enthusiasts and outdoor lovers alike.",
            gr: "Erleben Sie die Freiheit und Aufregung, die mit dem KiteActive-Lifestyle einhergeht! Ob Sie mit einem Kite durch die Lüfte schweben oder die Sonne an unberührten Stränden genießen – jeder Moment ist eine Gelegenheit für Abenteuer und eine Verbindung mit der Natur. Unsere Gemeinschaft gedeiht durch Leidenschaft, Kameradschaft und die Aufregung der Erkundung und bringt Kitesurf-Enthusiasten und Naturliebhaber zusammen.", // German translation
            du: "Omarm de vrijheid en opwinding die de KiteActive levensstijl met zich meebrengt! Of je nu door de lucht zweeft op een kite of geniet van de zon op ongerepte stranden, elk moment is een kans voor avontuur en verbinding met de natuur. Onze gemeenschap bloeit op passie, kameraadschap en de opwinding van verkenning, die kitesurfenthousiasten en liefhebbers van de buitenlucht samenbrengt."
        }
    },
    {
        id: "4",
        title: {
            en: "The KiteActive Experience",
            gr: "Das KiteActive-Erlebnis", // German translation
            du: "De KiteActive Ervaring"
        },
        para: {
            en: "Dive into the KiteActive experience, where adventure meets community! From the moment you join us, you’ll be immersed in a world of exhilarating kitesurfing, stunning landscapes, and like-minded enthusiasts who share your passion for the outdoors. Our carefully curated experiences cater to all skill levels, ensuring everyone can enjoy the thrill of riding the waves and harnessing the wind.",
            gr: "Tauchen Sie in das KiteActive-Erlebnis ein, wo Abenteuer auf Gemeinschaft trifft! Ab dem Moment, in dem Sie sich uns anschließen, werden Sie in eine Welt des aufregenden Kitesurfens, atemberaubender Landschaften und gleichgesinnter Enthusiasten eintauchen, die Ihre Leidenschaft für die Natur teilen. Unsere sorgfältig zusammengestellten Erlebnisse richten sich an alle Fähigkeitsstufen und stellen sicher, dass jeder den Nervenkitzel des Wellenreitens und das Nutzen des Windes genießen kann.", // German translation
            du: "Duik in de KiteActive ervaring, waar avontuur de gemeenschap ontmoet! Vanaf het moment dat je bij ons komt, word je ondergedompeld in een wereld van opwindend kitesurfen, prachtige landschappen en gelijkgestemde enthousiastelingen die jouw passie voor de buitenlucht delen. Onze zorgvuldig samengestelde ervaringen zijn gericht op alle vaardigheidsniveaus, zodat iedereen kan genieten van de opwinding van het rijden op de golven en het benutten van de wind."
        }
    }
];



const Textcontainer = ({ selectedLanguage }) => {
    return (
        <div>
            <div className="container d-flex flex-column align-items-center justify-content-center text_container">
                <div className="row">
                    <div className="col text-center">
                        <small className='text_small '>{content.title[selectedLanguage]}</small>
                        <h1 className=" surf_text"><span className='text_span'>{content.subtitle[selectedLanguage]}</span></h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        <p className='text_para'>
                            {content.para[selectedLanguage]}
                        </p>
                    </div>
                </div>
            </div>


            <div className="container mt-5">
                <div className="row">
                    {textdata.map(({ title, para }) => (
                        <div className="col-lg-6 p-2">
                            <div className='p-2'>
                                <h2 className=" text_head">
                                    <div className='text_span'>

                                        {title[selectedLanguage]}
                                    </div>
                                </h2>
                                <p className='text_para'>{para[selectedLanguage]}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <br />
            </div>
        </div>
    )
}

export default Textcontainer

import React from 'react'

const Thankyou = () => {
  return (
    <div>
    <h1 className='text-center mt-5 pt-5'>
        Thankyou
    </h1>
    </div>
  )
}

export default Thankyou